import dayjs from 'dayjs';
import { Link } from 'gatsby';
import React, { ReactNode } from 'react';

import SEO from '../../SEO';
import Global from '../../page-components/Global';

import CiteThis from '../../components/CiteThis';

import { smartypants } from '../../lib/utils';

interface ArticleTemplateProps {
  children    : ReactNode;
  pageContext : {
    frontmatter : {
      date        : string;
      description : string;
      title       : string;
    }
  }
}

export default function ArticleTemplate (props: ArticleTemplateProps): JSX.Element {
  const { children, pageContext } = props;

  const { date, description, title } = pageContext.frontmatter;

  return (
    <Global>
      <SEO
        title={smartypants(title)}
        description={smartypants(description)}
      />

      <div className="page-container">
        <div className="page-content">
          <Link to="/articles">
            &lsaquo; Articles
          </Link>

          <h1>{smartypants(title)}</h1>
          <div className="italic">{dayjs(date).format('MMMM D, YYYY')}</div>

          {children}
        </div>

        <CiteThis
          title={smartypants(title)}
          date={date}
        />
      </div>
    </Global>
  );
}
